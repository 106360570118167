import React from 'react';
import theme from '@/style';
import {SVGProps} from '@/types';

interface IMChatSvgProps extends SVGProps {
  active?: boolean;
}

const IMChat = ({active = false, width, height}: IMChatSvgProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 25"
      fill="none">
      <path
        d="M1.77176 23.4853C1.4288 23.5748 1.11756 23.2595 1.21159 22.9177L2.55426 18.0378C1.59402 16.3807 1.08968 14.5008 1.09208 12.5878C1.09438 6.57673 6.00853 1.68677 12.046 1.68677C14.9764 1.68814 17.7262 2.82343 19.7943 4.88364C20.8144 5.89436 21.6229 7.09646 22.1732 8.42038C22.7234 9.74429 23.0044 11.1637 22.9999 12.5965C22.9972 18.6071 18.083 23.4979 12.046 23.4979H12.0414C10.2082 23.497 8.40667 23.0396 6.80683 22.1711L1.77176 23.4853Z"
        fill={active ? theme.basicColor.primary : '#D5D6DB'}
      />
      <path
        d="M8.77586 7.8208C8.93507 7.82933 9.14926 7.7611 9.35916 8.26432C9.57477 8.78106 10.0945 10.0519 10.1595 10.1813C10.2238 10.3106 10.2666 10.4613 10.1809 10.6333C10.0945 10.8053 10.051 10.9134 9.92104 11.0648C9.79182 11.2147 9.64902 11.401 9.53265 11.5168C9.40271 11.6448 9.26706 11.7848 9.41842 12.0428C9.56978 12.3015 10.091 13.1473 10.862 13.8318C11.8523 14.711 12.6876 14.984 12.9475 15.1133C13.2067 15.242 13.358 15.2207 13.5094 15.0479C13.6607 14.8759 14.1576 14.2945 14.3304 14.0358C14.5039 13.7778 14.6767 13.8204 14.9144 13.9071C15.1515 13.9932 16.4273 14.6179 16.6865 14.7466C16.9464 14.8759 17.1191 14.9413 17.1841 15.0479C17.8111 16.1022 16.1251 17.3773 15.2814 17.5037C14.8352 17.5698 14.269 17.5975 13.6479 17.4006C13.2709 17.2819 12.7883 17.1227 12.1693 16.8569C9.56764 15.7388 7.86771 13.1317 7.73848 12.959L7.73785 12.9581C7.60557 12.783 6.67969 11.5574 6.67969 10.2886C6.67969 9.00252 7.48693 8.03211 8.77586 7.8208Z"
        fill={'#fff'}
      />
    </svg>
  );
};

export default IMChat;
